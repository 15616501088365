import * as pathToRegexp from "path-to-regexp";

import {appPath} from "./cars_app_path";

function parseParams<T extends {}>(path: string) {
    const compiler = pathToRegexp.compile(path);
    return (params: T) => compiler(params);
}

export interface IArticleDetailAndListParams {
    slug: string;
}

export interface IFullCarDetailParams {
    offerSlug: string;
    carId: string | number;
}

export interface IPageDetailParams {
    slug: string;
}
export interface ISearchResultUniversalParams {
    offerType?: string;
    type?: string;
    location?: string;
    subLocation?: string;
    subSubLocation?: string;
}

export interface ISearchResultUniversalTagParams extends ISearchResultUniversalParams {
    tag: string;
}
export interface IEditOfferParam {
    offerId: number;
}

export const appLink = {
    home: () => appPath.mainPage,
    aboutUs: () => appPath.aboutUs,
    contactUs: () => appPath.contact,
    news: () => appPath.news,

    article: {
        base: () => appPath.article.base,
        detailOrCategoryList: parseParams<IArticleDetailAndListParams>(appPath.article.detailOrCategoryList) // path handles both - article detail and list
    },
    favourites: () => appPath.favourites,
    fullOffer: {
        base: () => appPath.fullOffer.base,
        detail: {
            base: parseParams<IFullCarDetailParams>(appPath.fullOffer.detail.base)
        }
    },
    mainPage: () => appPath.mainPage,
    page: {
        base: () => appPath.page.base,
        detail: parseParams<IPageDetailParams>(appPath.page.detail) // path handles both - article detail and list
    },
    searchResult: {
        universal: parseParams<ISearchResultUniversalParams>(appPath.searchResult.universal),
        universalTag: parseParams<ISearchResultUniversalTagParams>(appPath.searchResult.universalTag)
    },
    admin: {
        login: appPath.admin.login,
        register: appPath.admin.register,
        userDashboard: appPath.admin.userDashboard.base,
        editOffer: parseParams<IEditOfferParam>(appPath.admin.userDashboard.edit)
    }
};
