import {Dispatch} from "redux";

import {IFullCarDetailParams} from "../../../../../../packages/utils/cars_routes";
import {apiLink} from "../../../app/routes/api_link";
import {IRouteState} from "../../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../../app/services/IServices";
import {appendQueryString} from "../../../app/utils/append_query_string";
import {createRequestActionTypes} from "../../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../../app/utils/request_response_utils/request";

export interface ICarDetailResponse {
    data: {
        id: number;
        title: string;
        content: string;
        slug: string;
        acf: {
            make: string;
            model: string;
            year: string;
            millage: string;
            engine: string;
            price: string;
            gallery: any;
            gearbox: string;
            engine_size: string;
            horse_power: string;
            city: string;
            wojew: string;
            first_registation_date: string;
            sellers_name: string;
            phone_number: string;
            body_type: string;
            damaged: string; //'tak/nie'
            imported: string;
        };
        details_basic: {
            version: string;
            generation: string;
            doors_counts: string;
            spots_counts: string;
            car_color: string;
            vin: string;
        };
        multimedia_options: {
            multimedia: string[];
            comfort_and_convenience: string[];
            appearance_and_materials: string[];
            safety: string[];
            additional_features: string[];
        };

        data_back?: any;
    };
}

export interface MultimediaOptions {
    multimedia: string[];
    comfort_and_convenience: string[];
    appearance_and_materials: string[];
    safety: string[];
    additional_features: string[];
}

export const fetchCarTypes = createRequestActionTypes({view: "Offer", type: "GET", name: "CarDetail"});

export const fetchCarsDetailsAtRoute =
    (services: Partial<IServices>, route: IRouteState<IFullCarDetailParams>) =>
    (dispatch: Dispatch): Promise<ICarDetailResponse | boolean> => {
        dispatch({type: fetchCarTypes.start});
        //const url = apiLink.cars.detail.base({})({carId: route.params.carId});
        const url = appendQueryString(apiLink.cars.detail.base({})({carId: route.params.carId}), {ss: "s1b"});
        return getRequest(services, url, "fetchOfferAtRoute")
            .then(async (json: ICarDetailResponse) => {
                dispatch({type: fetchCarTypes.success, result: json});

                return json;
            })
            .catch(async (err) => {
                //   console.log("FETCH DETAIL err", err);
                //await dispatch(redirectOrEnable404ResponseState(services, route.pathname));
                return false;
            });
    };
