import {appLink} from "@rw/cars_routes";
import {Dispatch} from "redux";

import {IRouteState} from "../../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../../app/services/IServices";
import {carsCommonUrl} from "../../../app/utils/read_environment_variables";
import {updateMetaData} from "../../../meta_data/actions/update_meta_data";

export const updateContactPageMetaData = (_1: Partial<IServices>, route: IRouteState<{}>) => (dispatch: Dispatch) => {
    const title = `Kontakt | Zakupauto - Sprzedaż Samochodów Nowych i Używanych`;
    const description = `Skontaktuj się z Zakupauto – specjalistami w sprzedaży samochodów nowych i używanych. Oferujemy wsparcie i doradztwo w wyborze idealnego auta. Zadzwoń lub napisz już dziś!`;
    const robots = "index, follow";
    const url = `${carsCommonUrl}${appLink.contactUs()}`;

    dispatch(updateMetaData(title, description, route, {robots, canonical: url}));

    return Promise.resolve(true);
};
