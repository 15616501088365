import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    className?: string;
}
export const MilageIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} viewBox="0 0 512 512">
            <rect x="64" y="89.373" width="16" height="45.255" transform="translate(-58.108 83.716) rotate(-45)" />

            <rect x="104" y="72" width="16" height="40" />
            <rect x="24" y="144" width="40" height="16" />
            <path d="M240.6,291.086,133.866,180.9a1.034,1.034,0,0,0-1.291.029,1.021,1.021,0,0,0-.241,1.241l90.129,124.074A40.237,40.237,0,0,1,240.6,291.086Z" />
            <path d="M425.706,158.294A240.014,240.014,0,0,0,58.227,464H453.773a240.053,240.053,0,0,0-28.067-305.706ZM462.229,299.76l-23.635,4.167-2.779-15.757L459.451,284Zm-30.1-82.688,8,13.856L420,242.552,412,228.7Zm-48.562-53.551,12.257,10.284L380.4,192.19l-12.256-10.284Zm-63.948-33.713,15.035,5.472-8.209,22.553-15.035-5.473ZM248,120h16v24H248Zm8,40a165.894,165.894,0,0,1,76.294,18.343A169.911,169.911,0,0,1,390.4,227.3l-12.793,9.61A151.95,151.95,0,0,0,208.5,183.6c-4.486,1.479-8.958,3.192-13.292,5.09-2.548,1.117-5.105,2.323-7.6,3.584L180.388,178c2.757-1.4,5.584-2.728,8.4-3.963,4.793-2.1,9.739-3.994,14.7-5.631A167.367,167.367,0,0,1,256,160Zm-63.623-30.192,8.209,22.552-15.035,5.473-8.209-22.553ZM52.549,284l23.636,4.167-2.779,15.757L49.771,299.76Zm0,87.994L49.771,356.24l23.635-4.167,2.779,15.757Zm27.318,66.931-8-13.856L92,413.448l8,13.856ZM92,242.552,71.867,230.928l8-13.856L100,228.7ZM342.854,421.89c-.08.072-.16.144-.242.213l-9.44,8A8,8,0,0,1,328,432H184a8,8,0,0,1-5.172-1.9l-9.44-8c-.082-.069-.162-.141-.242-.213a127.794,127.794,0,0,1-10.767-176.647l-39.162-53.911c-.076-.1-.15-.212-.221-.321a17.019,17.019,0,0,1,25.884-21.729c.094.089.186.179.276.272l46.384,47.885A127.928,127.928,0,0,1,342.854,421.89Zm44.752-169.878,13.842-8.024a168.2,168.2,0,0,1,8.519,16.8l-14.655,6.42A152.222,152.222,0,0,0,387.606,252.012Zm13.41,30.393,15.26-4.81a167.388,167.388,0,0,1,4.349,16.807l-15.678,3.2A151.055,151.055,0,0,0,401.016,282.4Zm6.23,30.389,15.921-1.588C423.72,316.751,424,322.4,424,328H408C408,322.93,407.746,317.813,407.246,312.794Zm24.887,126.134L412,427.3l8-13.856,20.133,11.624ZM459.451,372l-23.636-4.167,2.779-15.757,23.635,4.167Z" />
            <circle cx="256" cy="328" r="24" />
            <rect x="216" y="384" width="16" height="16" />
            <rect x="184" y="384" width="16" height="16" />
            <rect x="248" y="384" width="16" height="16" />
            <rect x="280" y="384" width="16" height="16" />
            <rect x="312" y="384" width="16" height="16" />
        </svg>
    );
};
