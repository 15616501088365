import {combineReducers, Reducer} from "redux";

import {fetchCarListTypes} from "../../../app/routes/data_fetcher/app_path_data_fetcher";
import {reduceRequestState, RequestState} from "../../../app/utils/request_response_utils/factories/reduce_request_state";
import {reduceResponse, reduceResponseField} from "../../../app/utils/request_response_utils/factories/reduce_response";
import {CarMakeTypes} from "../../../cars_data/cars_data";
import {fetchAllMakesForSearch, ICarsMakesSearch} from "../../../homepage/actions/fetch_makes_data_for_search";
import {ICarListOfferResponse} from "../../../meta_data/reducers/meta_data_reducer";
import {OfferType} from "../../utils/constants_offer";
import {fetchLatestCarListTypes} from "../actions/fetch_latest_cars_list";
import {fetchAndValidateLocationBySlugType, ILocation} from "../actions/fetch_location_by_slug_at_route";
import {
    fetchCarListLocationsAtRouteType,
    fetchCarListTopLocationsAtRouteType,
    RegionData,
    RegionTopCityData
} from "../actions/fetch_location_car_list_data_at_route";
import {fetchCarListLocationPlNameTypes, IPLLocationResponse} from "../actions/fetch_Locations_Pl_Data";
import {reduceLatestQuery} from "./reduce_latest_query";

export enum SortType {
    DEFAULT = "",
    PRICE_ASC = "price",
    PRICE_DESC = "-price",
    PRICE_PER_SQM_ASC = "price_per_sqm",
    PRICE_PER_SQM_DESC = "-price_per_sqm",
    NEWEST = "-created",
    SIZE_ASC = "size",
    SIZE_DESC = "-size"
}

export interface IOfferListQuery {
    // extends Partial<
    //     ICommonOfferListQueryParams & {
    //         location: string;
    //     }
    // > {
    offer_type: OfferType;
    page: string;
    per_page: number;
    type: CarMakeTypes;
    car_make_type: CarMakeTypes;
    sort?: SortType;
    slug?: string;
    location?: string;
    subLocation?: string;
}

export interface ICarListStore {
    cars: {
        //meta: IOfferListApiResponseMeta;
        cars: ICarListOfferResponse[];
        latest: ICarListOfferResponse[];
        allMakes: ICarsMakesSearch[];
    };
    location: {
        location: ILocation | null;
        locationRequestState: RequestState;
    };
    locationPLName: IPLLocationResponse;
    latestQuery: IOfferListQuery;
    carsLocations: RegionData;
    carsTopLocations: RegionTopCityData;
}
const carListReducerObj: Record<keyof ICarListStore, Reducer<any, any>> = {
    cars: combineReducers({
        // meta: reduceResponseField(fetchOfferListTypes, "meta"),
        cars: reduceResponseField(fetchCarListTypes, "data", []),
        latest: reduceResponseField(fetchLatestCarListTypes, "data", []),
        page: reduceResponseField(fetchCarListTypes, "page", 0),
        allMakes: reduceResponseField(fetchAllMakesForSearch, "data", [])

        // requestState: reduceRequestState(fetchOfferListTypes),
    }),
    location: combineReducers({
        location: reduceResponse(fetchAndValidateLocationBySlugType),
        locationRequestState: reduceRequestState(fetchAndValidateLocationBySlugType)
    }),
    locationPLName: reduceResponse(fetchCarListLocationPlNameTypes),
    latestQuery: reduceLatestQuery(fetchCarListTypes),
    carsLocations: reduceResponse(fetchCarListLocationsAtRouteType),
    carsTopLocations: reduceResponse(fetchCarListTopLocationsAtRouteType)
};
export const carListReducer = combineReducers(carListReducerObj);

//
// const offerListReducerObj: Record<keyof IOfferListStore, Reducer<any, any>> = {
//     offers: combineReducers({
//         meta: reduceResponseField(fetchOfferListTypes, "meta"),
//         collection_count: reduceResponseField(fetchOfferListTypes, "collectionCount", 0),
//         offers: reduceResponseField(fetchOfferListTypes, "data", []),
//         requestState: reduceRequestState(fetchOfferListTypes),
//         pageCount: reduceResponseField(fetchOfferListTypes, "pageCount", 0),
//         page: reduceResponseField(fetchOfferListTypes, "page", 0)
//     }),
//     location: combineReducers({
//         location: reduceResponse(fetchLocationBySlugTypes),
//         locationRequestState: reduceRequestState(fetchLocationBySlugTypes),
//         statistics: reduceResponse(fetchLocationStatisticsTypes),
//         statisticsRequestState: reduceRequestState(fetchLocationStatisticsTypes),
//         statisticsLatestQuery: reduceLatestQuery(fetchLocationStatisticsTypes),
//         recommended: reduceResponse(fetchRecommendedLocationsTypes),
//         recommendedRequestState: reduceRequestState(fetchRecommendedLocationsTypes),
//         recommendedLatestQuery: reduceLatestQuery(fetchRecommendedLocationsTypes)
//     }),
//     markers: combineReducers({
//         markers: reduceResponseField(fetchOfferListMarkersTypes, "result", null),
//         latestQuery: reduceResponseField(fetchOfferListMarkersTypes, "latestQuery"),
//         markersRequestState: reduceRequestState(fetchOfferListMarkersTypes)
//     }),
//     // TODO: do we even need this investment logic at this point? We don't link to investment listings in search anymore
//     investment: combineReducers({
//         investment: reduceResponse(fetchOfferListInvestmentTypes),
//         requestState: reduceRequestState(fetchOfferListInvestmentTypes)
//     }),
//     latestQuery: reduceLatestQuery(fetchOfferListTypes),
//     offerModalState: offerModalStateReducer,
//     seoDescription: reduceResponse(fetchOfferListSeoDescriptionTypes),
//     seoDescriptionLatestQuery: reduceLatestQuery(fetchOfferListSeoDescriptionTypes)
// };
//
// export const offerListReducer = combineReducers(offerListReducerObj);
