import React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import {appLink, parseOfferSlugToObject} from "../../../../../../packages/utils/cars_routes";
import {CallendarIcon, EngineIcon, GearBoxIcon, MilageIcon} from "@rw/icons";

import {CarMakeTypes} from "../../../cars_data/cars_data";
import {ICarListOfferResponse} from "../../../meta_data/reducers/meta_data_reducer";
import {OfferIcon} from "../../detail/components/car_box/OfferIcon";

const CarList: React.FC<CarListProps> = ({cars}) => {
    return (
        <div css={carListStyle}>
            {cars.map((car) => {
                const offerUrlParams = parseOfferSlugToObject(car.slug);
                const offerHref = appLink.fullOffer.detail.base(offerUrlParams);

                return (
                    <div key={car.id} css={carItemStyle}>
                        <a href={offerHref} css={hrefStyle}>
                            <img src={car.acf.gallery[0].sizes.medium} alt={`${car.acf.make} ${car.acf.model}`} css={carImageStyle} />
                            <div css={carDetailsStyle}>
                                <CarBoxHeading>
                                    <h3>
                                        {car.acf.make} {car.acf.model}
                                    </h3>
                                    <p>
                                        <strong css={priceStyle}>
                                            {car.acf.price.toLocaleString()} <small>PLN</small>
                                        </strong>
                                    </p>
                                </CarBoxHeading>
                                <div css={iconsWrapper}>
                                    <OfferIcon label={car.acf.year} icon={<CallendarIcon />} />
                                    <OfferIcon label={car.acf.engine} icon={<EngineIcon />} />
                                    <OfferIcon label={car.acf.gearbox} icon={<GearBoxIcon />} />
                                    <OfferIcon label={`${car.acf.millage} km`} icon={<MilageIcon />} />
                                </div>
                            </div>
                        </a>
                    </div>
                );
            })}
        </div>
    );
};

export default CarList;

const hrefStyle = css`
    display: block;
    color: #020309;
    width: 100%;

    &:hover {
        text-decoration: none;
        color: #020309;
    }
`;

const CarBoxHeading = styled.div`
    display: flex;
    justify-content: space-between;
`;
const iconsWrapper = css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0;
`;
const carListStyle = (theme: Theme) => css`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    margin: 20px 0;
    & > div {
        box-sizing: border-box;
    }

    @media (min-width: ${theme.breakpoints.screen_xs}) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: ${theme.breakpoints.screen_lg}) {
        grid-template-columns: repeat(3, 1fr);
    }
`;

const carItemStyle = css`
    border: 1px solid #ddd;
    display: flex;
    gap: 16px;
    align-items: center;
`;

const carImageStyle = css`
    width: 100%;
    height: 225px;
    object-fit: cover;
`;

const carDetailsStyle = css`
    display: flex;
    flex-direction: column;
    padding: 0 16px 16px 16px;
`;

interface CarListProps {
    cars: ICarListOfferResponse[];
    carMakeType: CarMakeTypes;
}
const priceStyle = css`
    font-size: 1.7rem;
`;
