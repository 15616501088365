import {useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";
import {capitalizeFirst} from "@rw/nodash";
import {pluralize} from "@rw/string_utils";

import {IStore} from "../../../app/reducers/hybrid_reducer";
import {getVoivodeshipName, Voivodeship} from "../../../app/types/locationTypes";

export const SeoLocationLinks = () => {
    const topRegions = useSelector((store: IStore) => store.carList.carsTopLocations);
    const locationRegions = useSelector((store: IStore) => store.carList.carsLocations);
    const latestQuery = useSelector((store: IStore) => store.carList.latestQuery);
    const pluralOffer = pluralize(["oferta", "oferty", "ofert"]);

    return (
        <div>
            {locationRegions && (
                <div css={containerStyle}>
                    <h2 css={titleStyle}>Sugerowane oferty z pobliskich lokalizacji</h2>
                    <div css={singleRegionContainer}>
                        <h3 css={regionTitleStyle}>{capitalizeFirst(getVoivodeshipName(locationRegions.region as Voivodeship))}</h3>
                        <ul css={listStyle}>
                            {locationRegions.cities.map((city) => (
                                <li key={city.citySlug}>
                                    <a
                                        href={`/${latestQuery.offer_type}/${latestQuery.type ? `${latestQuery.type}/` : ""}${locationRegions.region.toLowerCase()}/${city.citySlug}`}
                                        css={linkStyle}
                                        title={`Oferty w mieście ${city.city}${latestQuery.type ? ` - ${latestQuery.type}` : ""}`}
                                    >
                                        {city.city} ({city.offer_count} {pluralOffer(city.offer_count)})
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
            {topRegions && (
                <div css={containerStyle}>
                    <h2 css={titleStyle}>Sugerowane oferty z pobliskich lokalizacji</h2>
                    <div css={regionContainer}>
                        {Object.entries(topRegions).map(([region, cities]) => (
                            <div key={region} css={regionSectionStyle}>
                                <h3 css={regionTitleStyle}>
                                    <a href={`/${latestQuery.offer_type}/${latestQuery.type ? `${latestQuery.type}/` : ""}${region.toLowerCase()}/`}>
                                        {capitalizeFirst(getVoivodeshipName(region as Voivodeship))}
                                    </a>
                                </h3>
                                <ul css={listStyle}>
                                    {cities.map((city) => {
                                        return (
                                            <li key={city.citySlug}>
                                                <a
                                                    href={`/${latestQuery.offer_type}/${latestQuery.type ? `${latestQuery.type}/` : ""}${region.toLowerCase()}/${city.citySlug}`}
                                                    css={linkStyle}
                                                    title={`Oferty w mieście ${city.city}${latestQuery.type ? ` - ${latestQuery.type}` : ""}`}
                                                >
                                                    {city.city} ({city.offer_count} {pluralOffer(city.offer_count)})
                                                </a>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

const containerStyle = css`
    padding: 24px;
    display: flex;
    flex-direction: column;
`;
const singleRegionContainer = (theme: Theme) => css`
    display: flex;
    gap: 1rem;
    flex-direction: column;
`;

const regionContainer = (theme: Theme) => css`
    display: flex;
    flex-wrap: wrap;
    gap: 32px;

    flex: 1 1 100%;

    @media (min-width: ${theme.breakpoints.screen_md}) {
        flex: 1 1 23%;
        justify-content: space-between;
    }
`;

const titleStyle = css`
    font-size: 2.5rem;
    font-weight: 600;
    color: #333;
`;

const regionSectionStyle = css`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const regionTitleStyle = (theme: Theme) => css`
    font-size: 2rem;
    font-weight: 600;
    color: ${theme.colors.brand_black};
    margin-bottom: 0;
    a {
        color: ${theme.colors.brand_black};
    }
`;

const listStyle = css`
    list-style-type: none;
    padding: 0;
    margin: 0;
`;

const linkStyle = (theme: Theme) => css`
    text-decoration: none;
    color: ${theme.colors.brand_black};

    &:hover {
        color: ${theme.colors.brand_primary};
        text-decoration: underline;
    }

    &[title]:hover::after {
        content: attr(title);
        position: absolute;
        background-color: rgba(0, 0, 0, 0.75);
        color: white;
        padding: 5px;
        border-radius: 4px;
        font-size: 0.9rem;
        top: 100%;
        left: 0;
        z-index: 100;
    }
`;
