import {Dispatch} from "redux";

import {IStore} from "../../../app/reducers/hybrid_reducer";
import {apiLink} from "../../../app/routes/api_link";
import {IOfferListApiResponse, IOfferListResponseSSR} from "../../../app/routes/data_fetcher/app_path_data_fetcher";
import {IRouteState} from "../../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../../app/services/IServices";
import {appendQueryString} from "../../../app/utils/append_query_string";
import {createRequestActionTypes} from "../../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../../app/utils/request_response_utils/request";
import {ICarListOfferResponse} from "../../../meta_data/reducers/meta_data_reducer";
import {fetchLatestCarListTypes} from "./fetch_latest_cars_list";
import {ISearchResultRouteParams} from "./redirect_constraints";

export interface City {
    city: string;
    citySlug: string;
    offer_count: number;
}

export interface RegionData {
    region: string;
    cities: City[];
}
export interface RegionTopCityData {
    [region: string]: City[];
}

export const fetchCarListLocationsAtRouteType = createRequestActionTypes({name: "fetchCarListLocationsAtRoute", type: "GET", view: "cars_list"});
export const fetchCarListTopLocationsAtRouteType = createRequestActionTypes({name: "fetchCarTopListLocationsAtRoute", type: "GET", view: "cars_list"});

export const fetchCarListLocationsAtRoute =
    (services: Partial<IServices>, route: IRouteState<ISearchResultRouteParams>) => async (dispatch: Dispatch, getState: () => IStore) => {
        const {location, subLocation} = route.params;

        if (!location) {
            dispatch({type: fetchCarListTopLocationsAtRouteType.start});

            const url = apiLink.topLocations({})(null);
            return getRequest(services, url, "fetchCarTopLocations")
                .then((response: RegionTopCityData) => {
                    //   console.log("response top cities", response);
                    if (response) {
                        dispatch({type: fetchCarListTopLocationsAtRouteType.success, result: response});
                        return response;
                    }
                })
                .catch((err) => {
                    console.log("Error", err);
                    return null;
                });
        }

        dispatch({type: fetchCarListLocationsAtRouteType.start});

        const url = appendQueryString(apiLink.voivodeshipLocations({})(null), {wojew: location});
        return getRequest(services, url, "fetchCarListLocations")
            .then((response: RegionData) => {
                if (response) {
                    dispatch({type: fetchCarListLocationsAtRouteType.success, result: response});
                    return response;
                }
            })
            .catch((err) => {
                console.log("Error", err);
                return null;
            });
    };
