import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    accentColor?: string;
    className?: string;
}

export const GearBoxIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} viewBox="0 0 64 64">
            <path d="M58.29,12.75a7,7,0,1,0-8.54,6.87V30.5H33.5V19.62a7,7,0,1,0-3,0V30.5H14.25V19.62a7,7,0,1,0-3,0V44.38a7,7,0,1,0,3,0V33.5H30.5V44.38a7,7,0,1,0,3,0V33.5H51.25a1.5,1.5,0,0,0,1.5-1.5V19.62A7.05,7.05,0,0,0,58.29,12.75ZM28,12.75a4,4,0,1,1,4,4A4,4,0,0,1,28,12.75Zm-19.26,0a4,4,0,1,1,4,4A4,4,0,0,1,8.71,12.75Zm8.07,38.5a4,4,0,1,1-4-4A4,4,0,0,1,16.78,51.25Zm19.26,0a4,4,0,1,1-4-4A4,4,0,0,1,36,51.25ZM51.25,16.78a4,4,0,1,1,4-4A4,4,0,0,1,51.25,16.78Z" />
        </svg>
    );
};
