import {result} from "lodash";
import {Dispatch} from "redux";

import {IStore} from "../../../app/reducers/hybrid_reducer";
import {apiLink} from "../../../app/routes/api_link";
import {IRouteState} from "../../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../../app/services/IServices";
import {appendQueryString} from "../../../app/utils/append_query_string";
import {createRequestActionTypes} from "../../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../../app/utils/request_response_utils/request";
import {ISearchResultRouteParams} from "./redirect_constraints";

export interface IPLLocationResponse {
    cityName: string;
}

export const fetchCarListLocationPlNameTypes = createRequestActionTypes({name: "fetch", type: "GET", view: "car_list_location_pl_name"});

export const fetchLocationsPlData =
    (services: Partial<IServices>, route: IRouteState<ISearchResultRouteParams>) => async (dispatch: Dispatch, getState: () => IStore) => {
        const location = getState().carList.location.location;

        if (!location?.location || !location?.subLocation) {
            return;
        }

        const url = appendQueryString(apiLink.plLocation({})(null), {
            location: location?.location,
            subLocation: location?.subLocation
        });
        return getRequest(services, url, "fetchPlLocation")
            .then((response: IPLLocationResponse) => {
                if (response) {
                    dispatch({type: fetchCarListLocationPlNameTypes.success, result: response});
                    return response;
                }
            })
            .catch((err) => {
                console.log("Error", err);
                return null;
            });
    };
