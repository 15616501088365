import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    accentColor?: string;
    className?: string;
}


export const CallendarIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} viewBox="0 0 128 128">
            <path
                fill="#2e79bd"
                d="M33.67121,68.92694a1.22049,1.22049,0,0,0,1.23373-1.23358V57.67712a1.22052,1.22052,0,0,0-1.23373-1.23373H23.655a1.22052,1.22052,0,0,0-1.23373,1.23373V67.69336a1.2678,1.2678,0,0,0,1.26241,1.23387Z"
            />
            <path
                fill="#2e79bd"
                d="M57.22915,68.89768A1.22052,1.22052,0,0,0,58.46288,67.664V57.64772A1.22052,1.22052,0,0,0,57.22915,56.414H47.21306a1.22052,1.22052,0,0,0-1.23373,1.23373V67.664a1.2614,1.2614,0,0,0,1.23373,1.23373Z"
            />
            <path
                fill="#2e79bd"
                d="M80.78709,68.89768A1.22052,1.22052,0,0,0,82.02081,67.664V57.64772A1.22052,1.22052,0,0,0,80.78709,56.414H70.771a1.22052,1.22052,0,0,0-1.23373,1.23373V67.664A1.2614,1.2614,0,0,0,70.771,68.89768Z"
            />
            <path
                fill="#2e79bd"
                d="M57.22915,89.31195a1.22052,1.22052,0,0,0,1.23373-1.23373V78.062a1.22052,1.22052,0,0,0-1.23373-1.23373H47.21306A1.22052,1.22052,0,0,0,45.97933,78.062V88.07822a1.2614,1.2614,0,0,0,1.23373,1.23373Z"
            />
            <path
                fill="#2e79bd"
                d="M80.78709,89.31195a1.22052,1.22052,0,0,0,1.23373-1.23373V78.062a1.22052,1.22052,0,0,0-1.23373-1.23373H70.771A1.22052,1.22052,0,0,0,69.53727,78.062V88.07822A1.2614,1.2614,0,0,0,70.771,89.31195Z"
            />
            <path
                fill="#2e79bd"
                d="M94.32864,76.82826H104.345a1.23373,1.23373,0,0,1,1.23373,1.23373V88.07822a1.23373,1.23373,0,0,1-1.23373,1.23373H94.32893a1.23373,1.23373,0,0,1-1.23373-1.23373V78.0617A1.23344,1.23344,0,0,1,94.32864,76.82826Z"
            />
            <rect fill="#2e79bd" x="22.42125" y="76.82826" width="12.48369" height="12.48369" rx="1.23373" ry="1.23373" />
            <path
                fill="#2e79bd"
                d="M57.22911,109.69681a1.22052,1.22052,0,0,0,1.23373-1.23372V98.44685a1.22049,1.22049,0,0,0-1.23373-1.23358H47.213a1.22049,1.22049,0,0,0-1.23373,1.23358v10.01624a1.2614,1.2614,0,0,0,1.23373,1.23372Z"
            />
            <path
                fill="#2e79bd"
                d="M33.67114,109.69681a1.22052,1.22052,0,0,0,1.23373-1.23372V98.44685a1.22049,1.22049,0,0,0-1.23373-1.23358H23.65505a1.22049,1.22049,0,0,0-1.23373,1.23358v10.01624a1.2614,1.2614,0,0,0,1.23373,1.23372Z"
            />
            <path
                fill="#2e79bd"
                d="M94.32864,56.414H104.345a1.23373,1.23373,0,0,1,1.23373,1.23373V67.664a1.23373,1.23373,0,0,1-1.23373,1.23373H94.32893A1.23373,1.23373,0,0,1,93.0952,67.664V57.64743A1.23344,1.23344,0,0,1,94.32864,56.414Z"
            />
            <path
                fill="#2e79bd"
                d="M70.77071,97.21326H80.78709A1.23373,1.23373,0,0,1,82.02081,98.447v10.01609a1.23373,1.23373,0,0,1-1.23373,1.23373H70.77085a1.23358,1.23358,0,0,1-1.23358-1.23358V98.4467A1.23344,1.23344,0,0,1,70.77071,97.21326Z"
            />
            <path
                fill="#2d3e50"
                d="M118.64726,18.45745H99.76014V9.66169a5.81966,5.81966,0,1,0-11.63933,0v8.79576H39.87931V9.66169a5.81966,5.81966,0,0,0-11.63933,0v8.79576H9.35274a5.51623,5.51623,0,0,0-5.51481,5.51481V36.754H124.16207V23.97226A5.51623,5.51623,0,0,0,118.64726,18.45745Z"
            />
            <path
                fill="#2d3e50"
                d="M3.83793,118.64315A5.51625,5.51625,0,0,0,9.35274,124.158H118.64726a5.51625,5.51625,0,0,0,5.51481-5.51482V41.95283H3.83793Zm7.18075-69.50958H116.98133v67.84366H11.01867Z"
            />
        </svg>
    );
};
