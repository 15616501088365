import styled from "@emotion/styled";

interface IOfferIconProps {
    label: string;
    icon: JSX.Element;
}
export const OfferIcon = (props: IOfferIconProps) => {
    const FeatureIcon = () => <IconWrapper>{props.icon}</IconWrapper>;

    return (
        <TopFeature>
            <FeatureIcon />
            <div>
                <TopFeatureLabel>{props.label}</TopFeatureLabel>
            </div>
        </TopFeature>
    );
};

export const IconWrapper = styled.div`
    margin-right: 1rem;

    .svg-icon {
        height: 27px;
        width: 27px;
    }
`;

export const FeatureLabel = styled.div`
    color: ${(props) => props.theme.colors.gray_light};
    font-size: 1.4rem;
    font-weight: 600;
`;
const TopFeatureLabel = styled(FeatureLabel)`
    color: ${(props) => props.theme.colors.text_color};
`;

const TopFeature = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 30%;
    padding: 0.6rem 0;
    border-radius: 0.4rem;

    p {
        line-height: 1;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.screen_xs}) {
        margin-bottom: 1rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        > div {
            margin: 0 !important;
        }
    }
`;
