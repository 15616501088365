import * as React from "react";
import styled from "@emotion/styled";

interface IProps {
    separatorIcon?: JSX.Element;
}

export const SeparatorIcon = (props: IProps) => {
    return <SeparatorIconBase>{props.separatorIcon ? props.separatorIcon : separatorIcon}</SeparatorIconBase>;
};

const separatorIcon = <> &gt; </>;

const SeparatorIconBase = styled.i`
    padding: 0 0.8rem;
`;
