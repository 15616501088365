import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    accentColor?: string;
    className?: string;
    style?: React.CSSProperties;
}

export const EngineIcon = (props: IProps) => {
    return (
        <svg
            className={classNames(props.className, "svg-icon")}
            width="100%"
            height="100%"
            viewBox="0 0 96 96"
            style={{
                fillRule: "evenodd",
                clipRule: "evenodd",
                strokeLinejoin: "round",
                strokeMiterlimit: 2,
                ...props.style // Rozszerzamy o dodatkowe style, jeśli są przekazane
            }}
        >
            <rect id="piston" x="0" y="0" width="96" height="96" style={{fill: "none"}} />
            <path d="M34.502,65.21c-0.191,-1.469 -0.655,-2.908 -1.389,-4.239l-3.079,-5.583c-0.4,-0.725 -1.313,-0.989 -2.038,-0.589c-0.725,0.4 -0.989,1.313 -0.589,2.038l3.079,5.583c0.55,0.998 0.898,2.076 1.041,3.177c0.107,0.821 0.86,1.401 1.681,1.294c0.821,-0.107 1.401,-0.86 1.294,-1.681Zm-3.891,-16.383c1.923,3.484 4.045,7.327 5.727,10.368c0.741,1.342 1.722,2.511 2.876,3.461c0.639,0.526 1.586,0.434 2.111,-0.206c0.526,-0.639 0.434,-1.585 -0.205,-2.111c-0.865,-0.712 -1.6,-1.588 -2.156,-2.595c0,-0.001 -0,-0.001 -0.001,-0.002c-1.681,-3.039 -3.803,-6.882 -5.726,-10.365c-0.4,-0.724 -1.313,-0.988 -2.038,-0.588c-0.725,0.4 -0.988,1.313 -0.588,2.038Z" />
            <path d="M1.068,32.803c2.899,5.256 9.284,16.834 12.182,22.09c0.586,1.061 1.569,1.847 2.734,2.184c1.165,0.337 2.416,0.197 3.477,-0.389c4.974,-2.743 15.554,-8.578 20.528,-11.321c1.062,-0.585 1.848,-1.568 2.185,-2.733c0.336,-1.165 0.197,-2.416 -0.389,-3.478c-2.898,-5.256 -9.283,-16.833 -12.182,-22.089c-0.585,-1.062 -1.568,-1.847 -2.733,-2.184c-1.165,-0.337 -2.416,-0.197 -3.478,0.388c-4.974,2.743 -15.554,8.578 -20.528,11.321c-1.062,0.586 -1.847,1.569 -2.184,2.734c-0.337,1.165 -0.197,2.416 0.388,3.477Zm2.627,-1.448c-0.201,-0.365 -0.249,-0.796 -0.133,-1.196c0.116,-0.4 0.386,-0.739 0.751,-0.94l20.528,-11.321c0.365,-0.201 0.795,-0.249 1.196,-0.133c0.4,0.116 0.738,0.386 0.939,0.751c2.899,5.256 9.284,16.833 12.182,22.089c0.201,0.365 0.249,0.795 0.134,1.196c-0.116,0.4 -0.386,0.738 -0.751,0.939l-20.528,11.321c-0.366,0.201 -0.796,0.25 -1.196,0.134c-0.401,-0.116 -0.739,-0.386 -0.94,-0.751l-12.182,-22.089Z" />
            <path d="M9.987,36.718l-3.87,2.134c-0.725,0.4 -0.989,1.313 -0.589,2.038c0.4,0.725 1.313,0.989 2.038,0.589l3.87,-2.134c0.725,-0.4 0.989,-1.313 0.589,-2.038c-0.4,-0.725 -1.313,-0.989 -2.038,-0.589Zm22.038,-12.153l-17.582,9.696c-0.725,0.4 -0.989,1.313 -0.589,2.038c0.399,0.725 1.313,0.989 2.037,0.589l17.583,-9.696c0.725,-0.4 0.989,-1.313 0.589,-2.038c-0.4,-0.725 -1.313,-0.989 -2.038,-0.589Z" />
            <path d="M21.689,36.829c-2.337,1.289 -3.188,4.232 -1.899,6.569c1.289,2.337 4.232,3.188 6.569,1.9c2.337,-1.289 3.188,-4.233 1.9,-6.57c-1.289,-2.337 -4.233,-3.188 -6.57,-1.899Zm1.449,2.627c0.887,-0.489 2.004,-0.166 2.493,0.721c0.49,0.887 0.167,2.004 -0.72,2.494c-0.888,0.489 -2.005,0.166 -2.494,-0.721c-0.489,-0.887 -0.166,-2.005 0.721,-2.494Z" />
            <path d="M28.56,19.547l-25.235,13.917c-0.725,0.4 -0.989,1.313 -0.589,2.038c0.4,0.725 1.313,0.989 2.038,0.589l25.235,-13.917c0.725,-0.4 0.989,-1.313 0.589,-2.038c-0.399,-0.725 -1.313,-0.989 -2.038,-0.589Z" />
            <path d="M63.657,45.559l-6.843,12.408c-2.158,3.913 -2.324,8.619 -0.448,12.675c0,0.002 0.001,0.003 0.002,0.005c1.817,3.888 1.809,8.56 -0.421,12.603c-3.715,6.737 -12.203,9.191 -18.94,5.475c-6.738,-3.716 -9.192,-12.203 -5.476,-18.94c2.23,-4.044 6.179,-6.544 10.436,-7.095c0.001,0 0.001,0 0.002,0c4.426,-0.577 8.314,-3.226 10.47,-7.135c2.011,-3.637 4.551,-8.235 6.852,-12.403c0.4,-0.725 0.137,-1.638 -0.588,-2.038c-0.725,-0.401 -1.638,-0.137 -2.038,0.588c-2.301,4.167 -4.84,8.765 -6.852,12.402c-0.001,0.001 -0.001,0.001 -0.001,0.002c-1.695,3.073 -4.751,5.156 -8.231,5.609c-5.171,0.669 -9.968,3.708 -12.677,8.621c-4.515,8.188 -1.534,18.501 6.654,23.016c8.188,4.516 18.501,1.534 23.016,-6.654c2.71,-4.912 2.72,-10.59 0.514,-15.316c-1.475,-3.189 -1.344,-6.889 0.353,-9.966c-0,-0 6.843,-12.409 6.843,-12.409c0.4,-0.725 0.136,-1.638 -0.589,-2.038c-0.725,-0.399 -1.638,-0.135 -2.038,0.59Z" />
            <path d="M94.932,23.45c0.585,-1.062 0.725,-2.313 0.388,-3.478c-0.337,-1.165 -1.122,-2.148 -2.184,-2.734c-5.867,-3.235 -19.754,-10.894 -25.621,-14.129c-1.061,-0.585 -2.312,-0.725 -3.477,-0.388c-1.165,0.336 -2.148,1.122 -2.734,2.184c-3.406,6.176 -11.753,21.312 -15.159,27.488c-0.585,1.062 -0.725,2.313 -0.388,3.478c0.336,1.165 1.122,2.148 2.184,2.734c5.866,3.235 19.754,10.893 25.62,14.129c1.062,0.585 2.313,0.725 3.478,0.388c1.165,-0.337 2.148,-1.122 2.733,-2.184l15.16,-27.488Zm-2.627,-1.449l-15.16,27.488c-0.201,0.365 -0.539,0.635 -0.939,0.751c-0.401,0.116 -0.831,0.068 -1.196,-0.133c-5.866,-3.236 -19.754,-10.894 -25.621,-14.13c-0.365,-0.201 -0.635,-0.539 -0.75,-0.939c-0.116,-0.401 -0.068,-0.831 0.133,-1.196l15.159,-27.488c0.202,-0.365 0.54,-0.636 0.94,-0.751c0.401,-0.116 0.831,-0.068 1.196,0.133c5.866,3.235 19.754,10.894 25.62,14.129c0.365,0.202 0.635,0.54 0.751,0.94c0.116,0.401 0.068,0.831 -0.133,1.196Z" />
            <path d="M41.353,69.769c1.86,-0.656 3.982,-0.547 5.843,0.479c3.46,1.908 4.72,6.266 2.812,9.726c-1.908,3.46 -6.266,4.72 -9.726,2.812c-3.46,-1.908 -4.72,-6.266 -2.812,-9.726c0.4,-0.725 0.136,-1.638 -0.589,-2.038c-0.725,-0.4 -1.638,-0.136 -2.038,0.589c-2.707,4.91 -0.919,11.094 3.99,13.802c4.91,2.707 11.094,0.919 13.802,-3.99c2.707,-4.91 0.92,-11.094 -3.99,-13.802c-2.641,-1.456 -5.652,-1.612 -8.291,-0.681c-0.781,0.276 -1.191,1.134 -0.915,1.914c0.276,0.781 1.133,1.191 1.914,0.915Z" />
            <path d="M65.907,21.217l21.887,12.07c0.725,0.4 1.638,0.136 2.037,-0.589c0.4,-0.725 0.136,-1.638 -0.589,-2.038l-21.886,-12.07c-0.725,-0.4 -1.638,-0.136 -2.038,0.589c-0.4,0.725 -0.136,1.638 0.589,2.038Zm-9.114,-5.026l4.574,2.522c0.725,0.4 1.638,0.136 2.038,-0.589c0.4,-0.725 0.136,-1.638 -0.589,-2.038l-4.574,-2.522c-0.725,-0.4 -1.638,-0.136 -2.038,0.589c-0.4,0.725 -0.136,1.638 0.589,2.038Z" />
            <path d="M67.009,30.776c0.595,-0.155 1.248,-0.091 1.829,0.229c1.204,0.664 1.642,2.18 0.978,3.384c-0.664,1.204 -2.18,1.643 -3.384,0.979c-0.687,-0.379 -1.124,-1.035 -1.25,-1.752c-0.144,-0.815 -0.923,-1.36 -1.738,-1.217c-0.815,0.144 -1.361,0.923 -1.217,1.738c0.279,1.58 1.244,3.024 2.756,3.858c2.654,1.463 5.997,0.497 7.46,-2.157c1.464,-2.654 0.497,-5.996 -2.156,-7.46c-1.28,-0.706 -2.72,-0.847 -4.033,-0.506c-0.801,0.209 -1.282,1.028 -1.074,1.83c0.208,0.801 1.028,1.282 1.829,1.074Z" />
            <path d="M60.085,10.222l31,17.096c0.725,0.4 1.638,0.136 2.038,-0.589c0.4,-0.725 0.136,-1.638 -0.589,-2.038l-31,-17.096c-0.725,-0.4 -1.638,-0.136 -2.038,0.589c-0.4,0.725 -0.136,1.638 0.589,2.038Z" />
        </svg>
    );
};
