export const CarMakes: string[] = [
    "Audi",
    "BMW",
    "Ford",
    "MercedesBenz",
    "Opel",
    "Volkswagen",
    "Renault",
    "Abarth",
    "AC",
    "ACM",
    "Acura",
    "Aiways",
    "Aixam",
    "Alfa Romeo",
    "Alpina",
    "Alpine",
    "Amphicar",
    "Angelelli Automobili",
    "Ariel Motor",
    "Artega",
    "Aspark",
    "Aspid",
    "Aston Martin",
    "Aurus",
    "Austin",
    "Austin-Healey",
    "Autobianchi",
    "BAIC",
    "Bedford",
    "Bellier",
    "Bentley",
    "Boldmen",
    "Bolloré",
    "Borgward",
    "Brilliance",
    "Bristol",
    "Brute",
    "Bugatti",
    "Buick",
    "BYD",
    "Cadillac",
    "Caravans-Wohnm",
    "Carver",
    "Casalini",
    "Caterham",
    "Cenntro",
    "Changhe",
    "Chatenet",
    "Chery",
    "Chevrolet",
    "Chrysler",
    "Cirelli",
    "Citroen",
    "CityEL",
    "Corvette",
    "CUPRA",
    "Dacia",
    "Daewoo",
    "DAF",
    "Daihatsu",
    "Daimler",
    "Dallara",
    "Dangel",
    "De la Chapelle",
    "De Tomaso",
    "Delorean",
    "Devinci Cars",
    "DFSK",
    "Dodge",
    "Donkervoort",
    "DR Automobiles",
    "DS Automobiles",
    "Dutton",
    "e.GO",
    "Econelo",
    "Edran",
    "Elaris",
    "Embuggy",
    "EMC",
    "Estrima",
    "Evetta",
    "EVO",
    "Ferrari",
    "Fiat",
    "FISKER",
    "Forthing",
    "Foton",
    "Gac Gonow",
    "Galloper",
    "Gappy",
    "GAZ",
    "GEM",
    "GEMBALLA",
    "Genesis",
    "Giana",
    "Gillet",
    "Giotti Victoria",
    "GMC",
    "Goupil",
    "Great Wall",
    "Grecav",
    "GTA",
    "GWM",
    "Haima",
    "Hamann",
    "Haval",
    "Hiphi",
    "Holden",
    "Honda",
    "Hongqi",
    "HUMMER",
    "Hurtan",
    "Hyundai",
    "ICH-X",
    "Ineos",
    "Infiniti",
    "Innocenti",
    "Iso Rivolta",
    "Isuzu",
    "Iveco",
    "IZH",
    "JAC",
    "Jaguar",
    "Jeep",
    "Jensen",
    "Karma",
    "KG Mobility",
    "Kia",
    "Koenigsegg",
    "KTM",
    "Lada",
    "Lamborghini",
    "Lancia",
    "Land Rover",
    "LDV",
    "LEVC",
    "Lexus",
    "Lifan",
    "Ligier",
    "Lincoln",
    "Linzda",
    "Lorinser",
    "Lotus",
    "Lucid",
    "Lynk & Co",
    "M-Ero",
    "Mahindra",
    "MAN",
    "Mansory",
    "Martin",
    "Martin Motors",
    "Maserati",
    "Matra",
    "Maxus",
    "Maybach",
    "Mazda",
    "McLaren",
    "Mega",
    "Melex",
    "Mercury",
    "MG",
    "Micro",
    "Microcar",
    "Militem",
    "Minari",
    "Minauto",
    "MINI",
    "Mitsubishi",
    "Mitsuoka",
    "Morgan",
    "Moskvich",
    "MP Lafer",
    "MPM Motors",
    "NIO",
    "Nissan",
    "NSU",
    "Oldsmobile",
    "Oldtimer",
    "Omoda",
    "Ora",
    "Pagani",
    "Panther Westwinds",
    "Peugeot",
    "PGO",
    "Piaggio",
    "Plymouth",
    "Polestar",
    "Pontiac",
    "Porsche",
    "Proton",
    "Puch",
    "RAM",
    "Regis",
    "Reliant",
    "Rolls-Royce",
    "Rover",
    "Ruf",
    "Saab",
    "Santana",
    "SEAT",
    "Segway",
    "Selvo",
    "Seres",
    "Sevic",
    "SGS",
    "Shelby",
    "Shuanghuan",
    "Silence",
    "Singer",
    "Skoda",
    "Skywell",
    "smart",
    "SpeedArt",
    "Sportequipe",
    "Spyker",
    "SsangYong",
    "StreetScooter",
    "Studebaker",
    "Subaru",
    "Suzuki",
    "Talbot",
    "Tasso",
    "Tata",
    "Tazzari EV",
    "TECHART",
    "Tesla",
    "Togg",
    "Town Life",
    "Toyota",
    "Trabant",
    "Trailer-Anhänger",
    "Triumph",
    "Trucks-Lkw",
    "TVR",
    "UAZ",
    "Vanden Plas",
    "Vanderhall",
    "VAZ",
    "VEM",
    "VinFast",
    "Volvo",
    "Voyah",
    "Wartburg",
    "Weltmeister",
    "Wenckstern",
    "Westfield",
    "Wey",
    "Wiesmann",
    "XBus",
    "XEV",
    "Xpeng",
    "Zastava",
    "ZAZ",
    "Zeekr",
    "Zhidou",
    "Zotye",
    "Inne"
];
